import { walletBaseAPI } from 'shared/api/base';
import { WalletTxAgent } from 'shared/models/WalletTx';
import { WithdrawResponse } from '../models/response/WithdrawResponse';
import { WalletApiPath } from './consts';
import { WithdrawConfigResponse } from '../models/response/WithdrawConfigResponse';
import { WalletResponse } from '../models/response/WalletResponse';
import { IsFeeRequiredResponse } from '../models/response/IsFeeRequiredResponse';

const submitWithdraw = (amount: number) =>
  walletBaseAPI.post<WithdrawResponse>(WalletApiPath.SUBMIT_WITHDRAW, {
    amount,
  });

const getWithdrawConfig = () =>
  walletBaseAPI.get<WithdrawConfigResponse>(WalletApiPath.GET_WITHDRAW_CONFIG);

const getWallet = () =>
  walletBaseAPI.get<WalletResponse>(WalletApiPath.GET_WALLET);

const getIsFeeRequired = () =>
  walletBaseAPI.get<IsFeeRequiredResponse>(WalletApiPath.GET_IS_FEE_REQUIRED);

export type GetTxsData = { count: number; txs: WalletTxAgent[] };
const getTxs = (page: number) => {
  return walletBaseAPI.get<GetTxsData>(`/wallet/txs?page=${page}`);
};

export const walletAPI = {
  submitWithdraw,
  getWithdrawConfig,
  getIsFeeRequired,
  getWallet,
  getTxs,
};
